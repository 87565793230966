import { useTranslation } from 'react-i18next'
import Icon from '@common/components/Icon'
import classes from './ReviewsInfoModal.module.scss'

const ReviewsInfoModal = () => {
  const { t } = useTranslation(['common', 'checkout'])
  return (
    <div className={classes.ReviewsInfoModal__text}>
      <h1 className={classes.ReviewsInfoModal__title}>
        <Icon
          iconId='circle-interrogation-filled'
          fill='primary'
          size='xl'
          position='left'
        />
        {t('common:customer review modal.title')}
      </h1>

      <h2 className={classes.ReviewsInfoModal__subtitle}>
        {t('common:customer review modal.collect block.title')}
      </h2>
      <p>{t('common:customer review modal.collect block.paragraph 1')}</p>
      <p>
        <strong>
          {t('common:customer review modal.collect block.paragraph 2')}
        </strong>
      </p>

      <h2 className={classes.ReviewsInfoModal__subtitle}>
        {t('common:customer review modal.verify block.title')}
      </h2>
      <p>{t('common:customer review modal.verify block.paragraph 1')}</p>
      <p>{t('common:customer review modal.verify block.paragraph 2')}</p>
      <p
        dangerouslySetInnerHTML={{
          __html: t('common:customer review modal.verify block.paragraph 3'),
        }}
      />
      <p>{t('common:customer review modal.verify block.paragraph 4')}</p>
      <p>
        <strong>
          {t('common:customer review modal.verify block.paragraph 5')}
        </strong>
      </p>

      <h2 className={classes.ReviewsInfoModal__subtitle}>
        {t('common:customer review modal.classify block.title')}
      </h2>
      <p>{t('common:customer review modal.classify block.paragraph 1')}</p>
      
      <h2 className={classes.ReviewsInfoModal__subtitle}>
        {t('common:customer review modal.calculation block.title')}
      </h2>
      <p>{t('common:customer review modal.calculation block.paragraph 1')}</p>
    </div>
  )
}

export { ReviewsInfoModal as default }

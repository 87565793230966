import { getUtcBySummerSchedule } from '@root/utilities/site'
import footerLinks from './footerLinks'
import siteRoutes from '@root/engines/be-nl/config/siteRoutes'
import phone from './phone'

const config = {
  urls: {
    app: {
      staging: 'be-nl.energy-comparator-spa.s3.staging.aws.selectra.io',
      production: 'energie-vergelijken.callmepower.be'
    },
    api: {
      staging: 'energy-core.app.staging.aws.selectra.io',
      production: 'energy.selectra.tech'
    }
  },
  apiEndpointsModule: 'energy-core',
  locale: 'nl-BE',
  utc: getUtcBySummerSchedule(),
  openingHours: {
    monday: ['08:00', '19:00'],
    tuesday: ['08:00', '19:00'],
    wednesday: ['08:00', '19:00'],
    thursday: ['08:00', '19:00'],
    friday: ['08:00', '19:00'],
    saturday: ['08:30', '17:30'],
    sunday: []
  },
  footerLinks,
  siteRoutes,
  phone
}

export default config
